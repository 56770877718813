<template>
    <div class="footer-social">
        <UIGridContainer class="d-flex justify-content-between align-items-center">
            <UINavBrand
                :to="$locale.path('index')"
                class="dundle-logo d-inline-block mr-auto logo-container-min-width order-0"
            >
                <ThemeLogo />
            </UINavBrand>
            <hr class="m-0 mb-2 d-md-none" />
            <div class="d-flex align-items-center social-icons">
                <slot></slot>
                <a href="https://www.facebook.com/DundleCom" target="_blank">
                    <UIIcon :name="['fab', 'facebook-square']" class="social-icon facebook-icon" alt="Facebook Icon" />
                </a>
                <a href="https://www.instagram.com/dundle_com/" target="_blank">
                    <UIIcon :name="['fab', 'instagram']" class="social-icon instagram-icon" alt="Instagram Icon" />
                </a>
                <a href="https://twitter.com/Dundle_com/" target="_blank">
                    <UIIcon :name="['fab', 'twitter-square']" class="social-icon" alt="Twitter Icon" />
                </a>
                <a href="https://www.linkedin.com/company/dundle/" target="_blank">
                    <UIIcon :name="['fab', 'linkedin']" class="social-icon" alt="LinkedIn Icon" />
                </a>
            </div>
        </UIGridContainer>
    </div>
</template>

<script>
import { UINavBrand, UIGridContainer, UIIcon } from '@dundle/ui/components';
import { ThemeLogo } from '~/components/theme';
import TranslationMixin from '~/mixins/TranslationMixin';
export default {
    components: {
        UINavBrand,
        ThemeLogo,
        UIIcon,
        UIGridContainer,
    },
    mixins: [TranslationMixin],
};
</script>

<style lang="scss" scoped>
.footer-social {
    background: $color-grey-1;
    padding: 0.3rem 0;

    .dundle-logo {
        display: flex;
        height: 4rem;
        cursor: pointer;
        padding: 1px 0;

        img {
            height: 100%;
            max-height: 100%;
            display: flex;
        }
    }

    .social-icons {
        justify-content: space-between;

        .social-icon {
            color: $color-link;
            width: 2rem;
            height: 2rem;
            margin: 0.2rem;
            cursor: pointer;
            border-radius: 5px;

            &.instagram-icon {
                color: $color-bg;
                background: #d6249f;
                background: radial-gradient(
                    circle at 30% 107%,
                    #fdf497 0%,
                    #fdf497 5%,
                    #fd5949 45%,
                    #d6249f 60%,
                    #285aeb 90%
                );
            }

            &.facebook-icon {
                color: #4267b2;

                &:hover {
                    color: darken(#4267b2, 10%);
                }
            }
        }
    }
}
</style>
