import { render, staticRenderFns } from "./TrustpilotComponent.vue?vue&type=template&id=a3b77df8&scoped=true"
import script from "./TrustpilotComponent.vue?vue&type=script&lang=js"
export * from "./TrustpilotComponent.vue?vue&type=script&lang=js"
import style0 from "./TrustpilotComponent.vue?vue&type=style&index=0&id=a3b77df8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3b77df8",
  null
  
)

/* custom blocks */
import block0 from "@dundle/locale/data/trustpilot.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Flayout%2Fsidebar%2FTrustpilotComponent.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports