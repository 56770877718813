<template>
    <div class="stars d-flex" :class="`rating-${truncStars}-stars`">
        <div
            v-for="star in totalStars"
            :key="star"
            class="star text-center"
            :class="[{ 'half-star': getStarFillState(star), fill: roundHalf(starRating) >= star }, { small }]"
        >
            <nuxt-resource
                src="images/icons/trustpilot/trustpilot-star-m.svg"
                lazy
                alt="Trustpilot star"
                :width="150"
                ratio="1:1"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: ['starRating', 'small'],

    data() {
        return {
            totalStars: 5,
        };
    },

    computed: {
        truncStars() {
            return this.starRating >= 4.5 ? 5 : Math.trunc(this.roundHalf(this.starRating));
        },

        hasHalfStar() {
            return this.roundHalf(this.starRating) % 1 !== 0;
        },
    },

    methods: {
        getStarFillState(star) {
            return this.hasHalfStar && this.starRating < star && this.starRating > star - 1;
        },
        roundHalf(num) {
            return Math.round(num * 2) / 2;
        },
    },
};
</script>

<style lang="scss" scoped>
.star {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.3rem;
    background: $color-grey-3;
    @media screen and (max-width: 767px) {
        width: 1.6rem !important;
        height: 1.6rem !important;
        line-height: 1.4rem !important;

        img {
            width: 1.1rem !important;
        }
    }
    .review-star & {
        margin: 0.9rem 0 0.9rem 0;
        width: 1.6rem;
        height: 1.6rem;
        line-height: 1.4rem;

        img {
            width: 1.1rem;
            height: 1.1rem;
        }
    }

    .review-star-large & {
        width: 2rem;
        height: 2rem;
        line-height: 1.8rem;

        img {
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    &:not(:last-child) {
        margin-right: 0.3rem;
    }

    img {
        width: 1.6rem;
        z-index: 1;
        position: relative;
    }

    &.fill,
    &.half-star {
        .rating-1-stars &:not(.half-star),
        .rating-1-stars &:after {
            background: #ff3722;
        }

        .rating-2-stars &:not(.half-star),
        .rating-2-stars &:after {
            background: #ff8621;
        }

        .rating-3-stars &:not(.half-star),
        .rating-3-stars &:after {
            background: #ffce00;
        }

        .rating-4-stars &:not(.half-star),
        .rating-4-stars &:after {
            background: #72cf10;
        }

        .rating-5-stars &:not(.half-star),
        .rating-5-stars &:after {
            background: $color-trustpilot-green;
        }
    }

    &.half-star {
        position: relative;

        &:after {
            content: '';
            width: 50%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &.small {
        line-height: 1.667rem;
        height: 1.8rem;
        width: 1.8rem;
    }
}
</style>
