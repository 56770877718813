<template>
    <a class="trustpilot" :class="[{ horizontal }, { small }]" :href="trustpilotLink" target="_blank" @click="sendGTM">
        <div v-if="horizontal" class="text-rating mr-3 font-weight-bold">{{ trustpilotText }}</div>
        <div>
            <nuxt-resource
                class="logo"
                :class="[{ 'mb-2': !horizontal }, { small }]"
                :src="
                    light
                        ? 'images/icons/trustpilot/trustpilot-logo-white.svg'
                        : 'images/icons/trustpilot/trustpilot-logo.svg'
                "
                :height="24"
                :ratio="'37:150'"
                alt="Trustpilot logo"
                lazy
            />
        </div>

        <TrustpilotStars
            :class="[{ 'review-star-large': horizontal }, { small }]"
            :star-rating="starRating"
            :small="small"
        ></TrustpilotStars>

        <div class="score" :class="[{ 'mt-2': !horizontal }, { white: light }]">
            TrustScore <strong>{{ score }}</strong> | <strong>{{ reviews }}</strong> {{ translate('reviews') }}
        </div>
    </a>
</template>

<script>
import { mapGetters } from 'vuex';
import TrustpilotStars from '~/components/layout/sidebar/TrustpilotStars';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        TrustpilotStars,
    },
    mixins: [TranslationMixin],
    props: {
        horizontal: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters({
            starRating: 'trustpilot/getStars',
            score: 'trustpilot/getTrustScore',
            reviews: 'trustpilot/getReviewTotal',
        }),
        trustpilotLink() {
            return this.$locale.regio === 'GB'
                ? `https://uk.trustpilot.com/review/${this.$locale.domain}`
                : `https://${this.$locale.region}.trustpilot.com/review/${this.$locale.domain}`;
        },
        trustpilotText() {
            if (this.starRating === 5) {
                return 'Excellent';
            } else if (this.starRating >= 4) {
                return 'Great';
            } else if (this.starRating >= 3) {
                return 'Average';
            } else if (this.starRating >= 2) {
                return 'Poor';
            } else {
                return 'Bad';
            }
        },
    },
    methods: {
        sendGTM() {
            this.$gtm?.push({ event: 'home_page_trustpilot' });

            this.$analytics.event('view_page_external', { page: 'Trustpilot' });
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

.trustpilot {
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    display: block;
    text-decoration: none;

    .logo {
        height: 29px;
        aspect-ratio: math.div(37, 150);
        @media screen and (min-width: 768px) {
            width: 120px;
        }
    }

    &.small {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media screen and (max-width: 767px) {
        &.trustpilot-homepage {
            display: flex;
            flex-direction: column;
            align-items: center;
            .stars {
                @media screen and (min-width: 768px) {
                    width: 100;
                }
            }
            .logo {
                object-fit: contain !important;
            }
        }
        &:not(.trustpilot-homepage) {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            margin: 1.5rem auto;
            .stars {
                font-size: 0.85rem;
            }
            .logo {
                height: 24px;
                margin-left: 0.5rem;
                margin-bottom: 0 !important;
            }
            .text-rating,
            .score {
                display: none;
            }
        }
    }

    &.horizontal {
        .text-rating {
            order: 1;
        }
        .logo {
            order: 4;
        }
        .score {
            order: 3;
        }
        .review-star-large {
            order: 2;
            margin-right: 1.5rem;
        }
        display: flex;
        align-items: center;
        margin: 1.5rem auto;
        .stars {
            font-size: 0.85rem;
        }
        .logo {
            height: 20px;
            margin-left: 0.5rem;
        }
    }

    .white {
        color: #ffffff;
    }
}

.score {
    color: $color-grey-7;
    font-size: 0.9333333333333333rem;
}
</style>

<i18n src="@dundle/locale/data/trustpilot.json"></i18n>
